'use client';

import { useEffect, useState } from 'react';
import { Button, Tooltip, cn, useDisclosure } from '@nextui-org/react';
import { AlertTriangle, ArrowLeft, ArrowRight } from 'lucide-react';
import { ReportIssueMode, useUserInfoStore } from '@/src/lib/stores/user/user-information-store';
import useLocalStorageTheme from '@/lib/hooks/theme/local-storage-theme';
import UserFeedbackDialog from './user-feed-back-dialog';
export default function UserFeedBackFloating() {
  const [isMounted, setIsMounted] = useState(false);
  const feedBackState = useDisclosure();
  const userSettings = useUserInfoStore(state => state.userSettings);
  const updateUserSettings = useUserInfoStore(state => state.updateUserSettings);
  const isOpen = userSettings.reportIssueButtonmode == ReportIssueMode.OPEN;
  const {
    theme
  } = useLocalStorageTheme();
  useEffect(() => {
    setIsMounted(true);
  }, []);
  if (!isMounted) {
    return null;
  }
  return <Tooltip content="Pulse para abrir o cerrar" isDisabled={isOpen} data-sentry-element="Tooltip" data-sentry-component="UserFeedBackFloating" data-sentry-source-file="user-feed-back-floating.tsx">
      <div className="fixed bottom-[2rem] right-[1rem] z-50 flex h-[50px] items-center justify-center gap-x-2 rounded-md border-[0.2px] bg-content2 p-[0.5rem] md:right-[1rem] lg:bottom-[4.5rem]">
        {userSettings.reportIssueButtonmode == ReportIssueMode.CLOSED ? <ArrowLeft className="transform cursor-pointer transition-transform duration-300 hover:scale-110" onClick={() => {
        updateUserSettings({
          reportIssueButtonmode: ReportIssueMode.OPEN
        });
      }} size={20} /> : <ArrowRight className="transform cursor-pointer transition-transform duration-300 hover:scale-110" onClick={() => {
        updateUserSettings({
          reportIssueButtonmode: ReportIssueMode.CLOSED
        });
      }} size={20} />}
        <UserFeedbackDialog modalState={feedBackState} shouldUseReplay data-sentry-element="UserFeedbackDialog" data-sentry-source-file="user-feed-back-floating.tsx" />
        <Button className={cn(`transform transition-transform duration-500`, {
        hidden: userSettings.reportIssueButtonmode == ReportIssueMode.CLOSED
      })} onClick={() => {
        feedBackState.onOpen();
      }} size="sm" startContent={<AlertTriangle />} variant="light" data-sentry-element="Button" data-sentry-source-file="user-feed-back-floating.tsx">
          Reportar un problema
        </Button>
      </div>
    </Tooltip>;
}