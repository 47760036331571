'use client';

import { useEffect, useState } from 'react';
export default function useLocalStorageTheme() {
  const [theme, setTheme] = useState();
  useEffect(() => {
    window.addEventListener('storage', event => {
      if (event.key === 'theme') {
        setTheme(event.newValue as any);
      }
    });
    const actualTheme = localStorage.getItem('theme');
    if (actualTheme) {
      setTheme(actualTheme as any);
    }
    return () => {
      window.removeEventListener('storage', () => {});
    };
  }, []);
  return {
    theme
  };
}