'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useSession } from 'next-auth/react';
import MainErrorComponent from '@/components/pages/error/main-error-component';
import UserFeedBackFloating from '@/components/shared/client/feedback/user-feed-back-floating';
export default function GlobalError({
  error
}: any) {
  const session = useSession();
  useEffect(() => {
    Sentry.captureException(error, {
      extra: {
        session,
        vercelEnv: process?.env?.VERCEL_ENV ?? 'development'
      }
    });
    console.error(error);
  }, [error]);
  return <html lang="es-HN" data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body className="bg-background text-foreground">
        <MainErrorComponent data-sentry-element="MainErrorComponent" data-sentry-source-file="global-error.tsx" />
        <UserFeedBackFloating data-sentry-element="UserFeedBackFloating" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
}